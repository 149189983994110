import React from 'react';
import { Link } from 'gatsby';
import Main from '../components/page/Main';

function SingleTag({ data, pageContext }) {
  const { posts, tagName } = pageContext;

  return (
    <Main>
      <div>
        <div>Posts about {`${tagName}`}</div>
        <div>
          <ul>
            {posts.map((post, index) => {
              return (
                <li key={index}>
                  <Link to={post.frontmatter.path}>
                    {post.frontmatter.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Main>
  );
}

export default SingleTag;
